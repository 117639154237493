import React from 'react';
import LocationSection from '../components/location.js';

const Location = () => (
        <>
        <LocationSection/>
        </>
 
);

export default Location;